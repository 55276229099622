<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		append-to-body
		width="62%"
	>
		<form autocomplete="off" @submit.prevent="submit" v-if="loading">
			<v-row>
				<v-col cols="12" xl="3" lg="3" md="6" sm="12">
					<div
						class="form-group"
						:class="{ 'has-danger': errors.identity_document_type_id }"
					>
						<label class="control-label"
							>Tipo Doc. Identidad <span class="text-danger">*</span></label
						>
						<el-select
							v-model="form.identity_document_type_id"
							dusk="identity_document_type_id"
							@change="changeIdentityDocType"
						>
							<el-option
								v-for="option in identity_document_types"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.identity_document_type_id"
							v-text="errors.identity_document_type_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="6" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.number }">
						<label class="control-label"
							>Número <span class="text-danger">*</span></label
						>
						<div v-if="api_service_token != false">
							<x-input-service
								:identity_document_type_id="form.identity_document_type_id"
								v-model="form.number"
								@search="searchNumber"
							></x-input-service>
						</div>
						<div v-else>
							<el-input v-model="form.number" :maxlength="maxLength" dusk="number">
								<template
									v-if="
										form.identity_document_type_id === '6' ||
											form.identity_document_type_id === '1'
									"
								>
									<el-button
										type="primary"
										slot="append"
										:loading="loading_search"
										icon="el-icon-search"
										@click.prevent="searchCustomer"
									>
										<template v-if="form.identity_document_type_id === '6'">
											SUNAT
										</template>
										<template v-if="form.identity_document_type_id === '1'">
											RENIEC
										</template>
									</el-button>
								</template>
							</el-input>
						</div>
						<small
							class="form-control-feedback"
							v-if="errors.number"
							v-text="errors.number[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="5" lg="5" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.name }">
						<label class="control-label"
							>Nombre <span class="text-danger">*</span></label
						>
						<el-input v-model="form.name" dusk="name"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.name"
							v-text="errors.name[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.trade_name }">
						<label class="control-label">Nombre comercial</label>
						<el-input v-model="form.trade_name" dusk="trade_name"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.trade_name"
							v-text="errors.trade_name[0]"
						></small>
					</div>
				</v-col>

			</v-row>
			<v-row>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.department_id }">
						<label class="control-label">Departamento</label>
						<el-select
							v-model="form.department_id"
							filterable
							@change="filterProvince"
							popper-class="el-select-departments"
							dusk="department_id"
						>
							<el-option
								v-for="option in all_departments"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.department_id"
							v-text="errors.department_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.province_id }">
						<label class="control-label">Provincia</label>
						<el-select
							v-model="form.province_id"
							filterable
							@change="filterDistrict"
							popper-class="el-select-provinces"
							dusk="province_id"
						>
							<el-option
								v-for="option in provinces"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.province_id"
							v-text="errors.province_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.province_id }">
						<label class="control-label">Distrito</label>
						<el-select
							v-model="form.district_id"
							filterable
							popper-class="el-select-districts"
							dusk="district_id"
						>
							<el-option
								v-for="option in districts"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.district_id"
							v-text="errors.district_id[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.address }">
						<label class="control-label">Dirección</label>
						<el-input v-model="form.address" dusk="address"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.address"
							v-text="errors.address[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<div class="form-group" :class="{ 'has-danger': errors.telephone }">
						<label class="control-label">Teléfono</label>
						<el-input v-model="form.telephone" dusk="telephone"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.telephone"
							v-text="errors.telephone[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<div class="form-group" :class="{ 'has-danger': errors.email }">
						<label class="control-label">Correo electrónico</label>
						<el-input v-model="form.email" dusk="email"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.email"
							v-text="errors.email[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group" :class="{ 'has-danger': errors.birth_date }">
						<label class="control-label">Fecha de nacimiento</label>
						<el-date-picker
							v-model="form.birth_date"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
							:clearable="false"
						></el-date-picker>
						<small
							class="form-control-feedback"
							v-if="errors.birth_date"
							v-text="errors.birth_date[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="6" md="6" sm="12">
					<label>Descripción</label>
					<div class="form-group" :class="{ 'error--text': errors.description }">
						<el-input v-model="form.description" dusk="description"></el-input>
						<small
							class="error--text"
							v-if="errors.description"
							v-text="errors.description[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="12">
					<label>Cliente indeseable</label>
					<div class="form-group" :class="{ 'error--text': errors.undesirable }">
						<el-switch
							v-model="form.undesirable"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.undesirable"
							v-text="errors.undesirable[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="6" lg="6" md="6" sm="12" v-if="form.state">
					<div class="form-group">
						<label class="control-label">Estado del Contribuyente</label>
						<template v-if="form.state == 'ACTIVO'">
							<el-alert
								:title="`${form.state}`"
								type="success"
								show-icon
								:closable="false"
							></el-alert>
						</template>
						<template v-else>
							<el-alert
								:title="`${form.state}`"
								type="error"
								show-icon
								:closable="false"
							></el-alert>
						</template>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="6" md="6" sm="12" v-if="form.condition">
					<div class="form-group">
						<label class="control-label">Condición del Contribuyente</label>
						<template v-if="form.condition == 'HABIDO'">
							<el-alert
								:title="`${form.condition}`"
								type="success"
								show-icon
								:closable="false"
							></el-alert>
						</template>
						<template v-else>
							<el-alert
								:title="`${form.condition}`"
								type="error"
								show-icon
								:closable="false"
							></el-alert>
						</template>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="type === 'suppliers'">
				<v-col cols="12" xl="3" lg="3" md="3" sm="3" v-if="form.state">
					<div class="form-group" :class="{ 'has-danger': errors.perception_agent }">
						<el-checkbox v-model="form.perception_agent"
							>¿Es agente de percepción?</el-checkbox
						><br />
						<small
							class="form-control-feedback"
							v-if="errors.perception_agent"
							v-text="errors.perception_agent[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading_submit">
						<template v-if="loading_submit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import xInputService from 'Components/Global/InputService';
import { serviceNumber } from 'Mixins/functions';
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	components: {
		xInputService,
	},
	mixins: [serviceNumber, confirm],
	props: ['showDialog', 'type', 'recordId', 'external', 'document_type_id'],
	data() {
		return {
			loading_submit: false,
			loading: false,
			titleDialog: null,
			resource: 'people',
			errors: {},
			form: {},
			countries: [],
			all_departments: [],
			all_provinces: [],
			all_districts: [],
			provinces: [],
			districts: [],
			identity_document_types: [],
			api_service_token: false,
			max_number: null,
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},

		};
	},
	computed: {
		maxLength: function() {
			if (this.form.identity_document_type_id === '6') {
				return 11;
			}
			if (this.form.identity_document_type_id === '1') {
				return 8;
			}

			return 11;
		},
	},
	// beforeDestroy() {
	// 	this.$eventHub.destroy('reloadData');
	// },
	methods: {
		getStaticTables() {
			if (this.countries.length == 0) {
				this.$http
					.get(`/${this.resource}/get/static/tables/static-tables`)
					.then((response) => {
						this.countries = response.data.countries;
						this.all_departments = response.data.departments;
						this.all_provinces = response.data.provinces;
						this.all_districts = response.data.districts;
						this.identity_document_types = response.data.identity_document_types;
					});
			}
		},
		getDynamicTables() {
			this.$http
				.get(`/${this.resource}/get/dynamic/tables/dynamic-tables`)
				.then((response) => {
					this.max_number = response.data.max_number;
					this.api_service_token = response.data.api_service_token;

					if (this.recordId) {
						this.$http
							.get(`/${this.resource}/record/${this.recordId}`)
							.then((response) => {
								this.form = response.data.data;

								this.filterProvinces();
								this.filterDistricts();
							});
					}

					this.loading = true;
				});
		},
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				type: this.type,
				identity_document_type_id: '1',
				number: '',
				name: null,
				trade_name: null,
				country_id: 'PE',
				department_id: null,
				province_id: null,
				district_id: null,
				address: null,
				telephone: null,
				email: null,
				state: null,
				condition: null,
				perception_agent: false,
				birth_date: null,
				undesirable: false,
				description: null,
			};
		},
		async create() {
			await this.getStaticTables();
			await this.getDynamicTables();
			this.initForm();
			if (this.external) {
				if (this.document_type_id === '01') {
					this.form.identity_document_type_id = '6';
				}

				if (this.document_type_id === '03') {
					this.form.identity_document_type_id = '1';
				}
			}

			if (this.type === 'customers') {
				this.titleDialog = this.recordId ? 'Editar cliente' : 'Nuevo cliente';
			}

			if (this.type === 'suppliers') {
				this.titleDialog = this.recordId ? 'Editar proveedor' : 'Nuevo proveedor';
			}
		},
		submit() {
			if (this.form.identity_document_type_id == '1' && this.form.number.length != 8) {
				return this.$message.error('El DNI debe tener 8 caracteres numéricos');
			} else if (
				this.form.identity_document_type_id == '6' &&
				this.form.number.length != 11
			) {
				return this.$message.error('El RUC debe tener 11 caracteres numéricos');
			}

			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							if (this.external) {
								this.$eventHub.$emit('reloadDataPeople', response.data.id);
							} else {
								this.$eventHub.$emit('reloadData', this.resource);
							}
							this.close();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		changeIdentityDocType() {
			this.recordId == null ? this.setDataDefaultCustomer() : null;
		},
		setDataDefaultCustomer() {
			if (this.form.identity_document_type_id == '0') {
				this.form.number = this.max_number;
				this.form.name = 'Clientes - Varios';
			} else {
				this.form.number = '';
				this.form.name = null;
			}
		},
		searchCustomer() {
			this.searchServiceNumberByType();
		},
		searchNumber(data) {
			this.form.name =
				this.form.identity_document_type_id === '1'
					? data.nombre_completo
					: data.nombre_o_razon_social;
			this.form.trade_name =
				this.form.identity_document_type_id === '6' ? data.nombre_o_razon_social : '';

			// if (data.ubigeo[0] != '-') {
			//     this.form.location_id = data.ubigeo;
			// }
			this.form.address = data.direccion;
			this.form.department_id = data.ubigeo && data.ubigeo[0] != '-' ? data.ubigeo[0] : null;
			this.form.province_id = data.ubigeo && data.ubigeo[1] != '-' ? data.ubigeo[1] : null;
			this.form.district_id = data.ubigeo && data.ubigeo[2] != '-' ? data.ubigeo[2] : null;
			this.form.condition = data.condicion;
			this.form.state = data.estado;

			this.filterProvinces();
			this.filterDistricts();
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
